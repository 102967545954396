<template>
    <div class="employeesBill">
        <div style="color: #D98207; font-size: 13px; padding: 10px; box-sizing: border-box;">
            提示：因微信限制，每天只能向一个用户转账10次，大于10次的返利将在次日凌晨到账。
        </div>
        
        <div style="background: #fff; border-radius: 5px; box-sizing: border-box; margin: 5px; padding: 15px 8px; text-align: center;">
            <div style="color: #202020; font-size: 28px; font-weight: 500;">{{billingDetails.total_amount}}</div>
            <div style="color: #424242; font-size: 13px; margin-top: 5px; margin-bottom: 35px;">累计收益（元）</div>
            <div style="display: flex; align-items: center; justify-content: space-between; color: #424242; font-size: 13px;">
                <div>已到账金额：{{billingDetails.payed_amount}}元</div>
                <div>次日到账金额：{{billingDetails.wait_amount}}元</div>
            </div>
        </div>

        <div v-for="(item, index) in dataList" :key="index" style="background: #fff; border-radius: 5px; box-sizing: border-box; margin: 7px 5px; padding: 15px 8px; text-align: center; color: #424242; font-size: 13px;">
            <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 7px;">
                <div>{{item.topic.name}}</div>
                <div>
                    {{item.class_id === 1 ? "一级：" : "二级："}}
                    <span style="color: #D98207;">{{item.amount}}元</span>
                </div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                <div>下单用户</div>
                <div>{{item.tuan ? item.tuan.real_name + " " + item.tuan.real_telphone : "---"}}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                <div>下单商品</div>
                <div>{{item.good.good_name}}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                <div>下单时间</div>
                <div>{{item.tuan ? item.tuan.createtime : "---"}}</div>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between;">
                <div>到账时间</div>
                <div>{{item.updatetime}}</div>
            </div>
        </div>

        <div style="text-align: center;">
            <div @click="handleClickMore" style="font-size: 14px; color: #828282; padding: 5px 0; display: inline-block;">
                加载更多
            </div>
        </div>

        <!-- 提示 -->
        <div v-if="isShowHint">
            <div style="color: #fff; font-weight: 400; font-size: 12px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: rgba(0, 0, 0, .6); border-radius: 4px; padding: 10px 15px;">
                {{hintContent}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: 1,
            event_id: "",
            billingDetails: {},
            dataList: [],
            // 是否显示提示
            isShowHint: false,
            // 提示内容
            hintContent: '',
        }
    },
    mounted() {
        this.event_id = parseInt(this.$route.query.event_id);
        this.getEmployeesMoneyData();
    },
    methods: {
        /** 显示提示 */
        showMessage(message) {
            this.hintContent = message;
            this.isShowHint = true;
            const timeout = setTimeout(() => {
                this.isShowHint = false;
                clearTimeout(timeout);
            }, 1000);
        },

        /** 处理点击加载更多 */
        handleClickMore() {
            if(this.billingDetails.total <= this.page * 15) {
                this.showMessage("没有更多了");
                return;
            }
            this.page++;
            this.getEmployeesMoneyData();
        },

        /** 获取 员工佣金 数据 */
        getEmployeesMoneyData() {
            this.$axios.post("/teams/orderRewardList", {
                event_id: this.event_id,
                page: this.page,
            }).then(res => {
                this.billingDetails = res;
                this.dataList.push(...res.data);
                console.log(this.dataList);
            }).catch(error => {
                this.showMessage(error);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    .employeesBill {
        background: #f6f6f6;
        min-height: 100vh;
        height: 100%;
    }
</style>